.main-footer {
    color:#bbb;
    text-align: center;
    background-color : #222;
    position: relative;
    bottom:0;
    left: 0;
    width:100%;
}

